<template>
    <div id="app">
        <img id="img_garden_1" alt="Garden" src="./assets/1M_Garden_2.jpg">
        

        <UnderConstruction msg="Welcome to 1Moment Studio Home Page" />
        <br />
        <img id="one_moment_logo" alt="1M logo" src="./assets/1Moment_color-RGB.png">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <!--<img id="one_moment_under_construction" style="max-width: 70%;" alt="1M logo" src="./assets/one_moment_under-construction.png">-->
        <br />
        <br />
        <br />

    </div>
</template>

<script>
    import UnderConstruction from './components/UnderConstruction.vue'

    //1M • Invoice.App Under Construction

    export default {
        name: 'OMUC',
        components: {
            UnderConstruction
        }
    }
</script>

<style>
    #app {
        font-family: 'Nunito', sans-serif;
        color: #333;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 0px;
    }

    #img_garden_1 {
        width: 100%;
    }

    #one_moment_logo {
        width: 50%;
    }

</style>
