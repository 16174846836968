<template>
    <div class="hello">


        <div class="container">

            <div class="fibi">
                <!--<div class="image">
                    <img id="fibi_img" alt="Fibi" src="../assets/Fibi_1.png">
                </div>-->
                <div class="text" style="text-align: center; color: #3A3B3C; ">
                    <!-- Alex Brush -->
                    <p style="font-family: 'Fredericka the Great', cursive; font-size: 1.5em; " class="ma-0">
                        Hello, Traveler! My name is Fibi.
                        <br />
                        Welcome to 1Moment • Studio.
                        <br />
                        <br />
                        Let me present our startup pre-launch site:
                        <br />
                        Tokenized Solution for Founders Settlements
                        <br />
                        <a target="_blank" href="https://treasury.1m.studio">1Moment • Treasury</a>
                        <br />
                        <br />
                        Become our founder!
                        <br />
                        Let's tokenize crowdfunding investments together!


                    </p>

                    <br />
                    <br />
                    <br />
                    <br />

                </div>
            </div>


            <!--<div class="luna">
                <div class="image">
                    <img id="luna_img" alt="Luna" src="../assets/Luna_1.png">
                </div>
                <div class="text">
                    <h5>Hello, my name is Luna. <br /> I'll show you where to find features and tools in the application.</h5>
                </div>

            </div>-->
        </div>


    </div>
</template>

<script>
    export default {
        name: 'UnderConstruction',
        props: {
            msg: String
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>



    .fibi, .luna {
        clear: both;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /*touch screen*/
    @media (pointer:none), (pointer:coarse) {

        .fibi, .luna {
            flex-direction: column;
        }

        .text {
            text-align: center;
        }

        div.hello h3 {
            font-size: 18px;
        }

        #one_moment_under_construction {
            width: 200px;
        }
    }

    /*screen with crusor*/
    @media (pointer:fine) {

        .text {
            text-align: left;
            padding-left: 40px;
            padding-right: 20px;
        }

        div.hello h3 {
            font-size: 2em;
        }

        #one_moment_under_construction {
            width: 400px;
        }
    }

    div.image {
        max-width: 250px;
    }

    h5 {
    }

    .image {
        flex-basis: 40%;
    }

    .text {
        font-size: 22px;
        max-width: 850px;
    }

    div.hello {
        margin: 5px 20%;
    }

        div.hello h3 {
            font-family: 'Nunito', sans-serif;
            font-weight: bold;
            color: #333;
            margin-block-start: 10px;
            margin-block-end: 40px;
        }

    #fibi_img, #luna_img {
        width: 250px;
        height: 250px;
        max-width: 250px;
        max-height: 250px;
        min-width: 200px;
        min-height: 200px;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
